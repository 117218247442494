<template>
    <div class="topLast">
        <div class="welfareLeft" :class="isShowBorder ? 'border' : ''" >
            <div class="wtop">
                <span class="wtit">{{ $t('welfare.myWelfarePoints') }}</span>
                <div class="fldetail pointer" @click="todetail()">
                    <span class="fltit" v-show="!isShowBorder">{{ $t('welfare.viewWelfarePoints') }}</span>
                    <svg-btn class="le" icon-class="icon-direction-right"></svg-btn>
                </div>
            </div>
            <div class="wcontent">
                <img class="iconFl" :src="$utils.getPng('web4/icon-fl')" alt="" srcset="">
                <div class="shuzi">{{ point_int || $store.state.userInfo.point_int }}</div>
            </div>
            <div class="intrBox">
                <img class="iconlb" :src="$utils.getPng('web4/icon-lb')" alt="" srcset="">
                {{$t('welfare.welfareIntr')}}
            </div>
        </div>
        <div class="welfareRight" v-if="welfareTaskSwitch == 1" :class="isShowBorder ? 'border' : ''">
            <div class="wtop">
                <span class="wtit"> {{$t('welfare.getpoint')}}</span>
            </div>
            <div class="wcontent">
                <img class="iconmovie" :src="$utils.getPng('web4/icon-movie')" alt="" srcset="">
                <div class="name">
                    <div class="name1">{{ daily_tasks.name }}<img class="iconFl" :src="$utils.getPng('web4/icon-fl')"
                            alt="" srcset=""><span class="number">{{ daily_tasks.point }}</span></div>
                    <div class="name2">{{ daily_tasks.desc }}</div>
                </div>
                <div class="btnBox pointer" @click="watchMoveFun">
                    <div class="btn1">{{ $t('welfare.watchVideotoApp') }}</div>
                    <div class="btn2">{{ daily_tasks.status_name }}</div>
                </div>
            </div>

        </div>
        <WatchMovieDia ref="WatchMovieDiaRef"></WatchMovieDia>
        
    <!-- <PayVipPopup :isShow="isPayVipPopupShow" @handleClose="isPayVipPopupShow = false"></PayVipPopup> -->
    </div>
</template>

<script>
import WatchMovieDia from "@/components/Dialog/WatchMovieDia.vue";
// import PayVipPopup from "@/components/Member/PayVipPopup.vue";
import { downPageExtraInfo } from '@/utils/apiList/welfare';
import { welfareTaskInfo } from "@/utils/apiList/welfare";

export default {
    components: {
        WatchMovieDia,
    },
    props: {
        isShowBorder: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            point_int: 0,
            daily_tasks: [],
            welfareTaskSwitch: 0,
            // isPayVipPopupShow: false
        }
    },
    created() {
        this.getTaskList()
    },
    methods: {
        getTaskList() {
            welfareTaskInfo().then((res) => {
                if (res.status == "1") {
                    this.daily_tasks = res.data.daily_tasks || [];
                    this.point_int = res.data.point || '';
                    this.welfareTaskSwitch = res.data.welfareTaskSwitch;
                    this.$parent.dailyTaskList = res.data.daily_tasks || [];
                    this.$parent.newbieTasks = res.data.newbie_tasks || [];
                    this.$parent.exchangeList = res.data.exchange_list || [];
                    this.$parent.point_int = res.data.point || '';
                }
            });
        },
        todetail() {
            if (this.isShowBorder) {
                window.open('/welfare', '_blank')
            } else {
                this.$parent.todetail()
            }

        },
        watchMoveFun() {
            if (this.$store.state.userInfo.phone == "") {
                this.$parent.bindType = 1;
                this.$parent.isShowBindPhone = true;
                return;
            }
            this.$refs.WatchMovieDiaRef.watchMovieFlag = true
        },
    }

}
</script>

<style lang="scss">
.topLast {
    display: flex;
    margin-top: 20px;

    .wtop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        @include useTheme {
            color: getVar("text-color1-2C2C2C");
        }

        .fldetail {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: 400;
            font-size: 12px;
            min-width: 80px;
            justify-content: flex-end;

            @include useTheme {
                color: getVar("text-color1-8a8b92");
            }

            .le {
                width: 12px;
                margin-left: 4px;
            }
        }
    }

    .welfareLeft {
        @include useTheme {
            background: getVar("text-color1-ffffff");
            border: getVar('border-color-e5e5e5');
        }
        box-shadow: 0px 1px 8px -1px rgba(0,0,0,0.02);
        width: calc(50% - 12px);
        height: 212px;
        border-radius: 16px;
        padding: 24px;
        min-width: 302px;
        flex-shrink: 0;

        // @media screen and (max-width: 1440px) {
        //     width: calc(40% - 8px);
        //     padding: 20px;
        //     height: 180px;
        // }


        .wcontent {
            display: flex;
            align-items: center;
            margin-top: 32px;
            // @media screen and (max-width: 1440px) {
            //     margin-top: 24px;
            // }

            .iconFl {
                width: 32px;
                height: 32px;
            }

            .shuzi {
                height: 40px;
                font-weight: 600;
                font-size: 32px;
                color: #F67942;
                line-height: 40px;
                margin-left: 8px;
            }
        }

        .intrBox {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            margin-top: 32px;
            display: flex;
            align-items: center;
            font-size: 14px;

            // @media screen and (max-width: 1440px) {
            //     margin-top: 24px;
            // }
            @include useTheme {
                background: getVar("text-color1-f9f9f9");
            }

            color: #F67942;
            padding-left: 16px;

            .iconlb {
                width: 20px;
                margin-right: 8px;
                flex-shrink: 0;
            }

            // @media screen and (max-width: 1440px) {
            //     font-size: 12px;
            //     padding-left: 8px;

            //     .iconlb {
            //         width: 16px;
            //         margin-right: 3px;
            //     }
            // }

        }

        // @media screen and (max-width: 1440px) {
        //     .intrBox {
        //         height: 32px;
        //     }
        // }
    }

    .welfareRight {
        @include useTheme {
            background: getVar("text-color1-ffffff");
            border: getVar('border-color-e5e5e5');
        }

        box-shadow: 0px 1px 8px -1px rgba(0,0,0,0.02);
        width: calc(50% - 12px);
        height: 212px;
        border-radius: 16px;
        padding: 24px;
        margin-left: 24px;

        // @media screen and (max-width: 1440px) {
        //     width: calc(60% - 8px);
        //     margin-left: 16px;
        //     padding: 20px;
        //     height: 180px;
        // }

        .wcontent {
            display: flex;
            align-items: center;
            margin-top: 36px;

            // @media screen and (max-width: 1440px) {
            //     margin-top: 32px;
            // }

            .iconmovie {
                width: 100px;
            }

            .iconmovie {
                // @media screen and (max-width: 1440px) {
                //     width: 72px;
                // }
            }

            .name {
                margin-left: 12px;
                flex-shrink: 0;

                // @media screen and (max-width: 1440px) {
                //     margin-left: 8px;
                // }

                .name1 {
                    height: 24px;
                    font-weight: 400;
                    font-size: 16px;

                    @include useTheme {
                        color: getVar("text-color1-2C2C2C");
                    }

                    line-height: 24px;
                    display: flex;
                    align-items: center;

                    .iconFl {
                        width: 20px;
                        margin: 0 4px 0 6px;
                    }

                    .number {
                        height: 22px;
                        font-weight: 600;
                        font-size: 16px;
                        color: #F67942;
                        line-height: 22px;
                    }
                }

                .name2 {
                    height: 22px;
                    font-weight: 400;
                    font-size: 14px;

                    @include useTheme {
                        color: getVar("text-color1-8a8b92");
                    }

                    line-height: 22px;
                    margin-top: 4px;
                }
            }

            .btnBox {
                // width: 132px;
                padding: 0 24px;
                height: 52px;
                background: #F67942;
                border-radius: 26px;
                margin-left: auto;
                margin-right: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;

                .btn1 {
                    height: 22px;
                    line-height: 22px;
                }

                .btn2 {
                    height: 14px;
                    line-height: 14px;
                }

                // @media screen and (max-width: 1440px) {
                //     // width: 118px;
                //     padding: 0 20px;
                // }
            }

        }
    }

    .border {
        @include useTheme {
            border: getVar('border-color-e5e5e5');
        }
    }
}
</style>