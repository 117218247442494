<template>
  <div class="vip">
    <div class="xin-vip">
      <TabList curId="0" style="margin-bottom: 18px"></TabList>
      <PayOrderVip @allQuan="allQuanFn('idPayJuris')"></PayOrderVip>
      <div class="PayJurisdiction-box">
        <div id="idPayJuris" class="PayJurisdiction-title">全部权益</div>
        <PayJurisdiction></PayJurisdiction>
      </div>
    </div>
  </div>
</template>

<script>
import TabList from "../TabList/index.vue";
import PayOrderVip from "@/components/PayOrderVip/index";
import PayJurisdiction from "@/components/PayJurisdiction/index";
export default {
  components: {
    TabList,
    PayOrderVip,
    PayJurisdiction,
  },
  data() {
    return {
      PayJurisisShow: false,
    };
  },
  created() {
    
    this.$store.commit("setAttr", {
      name: "route_ids",
      val: ['A1'],
    })
    this.$store.commit("setAttr", {
      name: "statusNum",
      val: '',
    })
    this.$store.commit("setAttr", {
      name: "voucher",
      val: '',
    })
    this.$store.commit("setAttr", {
      name: "is_pc",
      val: 0,
    })
  },
  mounted() {},
  methods: {
    allQuanFn(sectionId){
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
