<template>
  <div id="homepage" ref="addScroll" @scroll="scrollBox()"  v-scroll-outer="scrollBox">
    <div class="homepage">
      <div class="Navbar" :class="{'Navbar2':showNavbar2}">
        <div class="top-menu">
          <svg-btn  @click="goRouter(0)" class="logo pointer" style="padding-right: 10px;"  :icon-class="showNavbar2 ? 'icon-logo0-new':'icon_logo_fff'"></svg-btn>
          <svg-btn @click="goRouter(0)" class="logo1 pointer"
            :class="showNavbar2 ? 'color1' : 'color2'"
            :icon-class="$t('home.FEEMOO')"></svg-btn>
          <span class="nav-name" :class="{'nav-name2':index==3}" v-for="(item,index) in navData" :key="item" @click="goRouter(index)">{{ $t(item)}}</span>
           <div class="dropDownBox" @mouseenter="showDropFun()" @mouseleave="showDropFun()">
           <div class="titBox" :class="showNavbar2 ?'color1':'color2'" >
              <div class="tit">{{ $store.state.language == 'en'?'English':'简体中文'}}</div>
               <svg-btn class="icon" icon-class="icon-up-close"></svg-btn>
            </div>
            <div class="user2"></div>
            <div class="dropItem">
              <div class="one pointer" @click="changeLanguage(0)" >
                <div class="ontit">English</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(1)" >
                <div class="ontit">简体中文</div>
              </div>
            </div>
           </div>
          <div class="nologin" v-if="!islogin">
            <span class="com-btn" :class="showNavbar2 ? 'login-btn2' :'login-btn'" @click="goLogin(1)">{{ $t("home.Login") }}</span>
            <span class="com-btn" :class="showNavbar2 ? 'register-btn2' :'register-btn'" @click="goLogin(1, 'register')">{{ $t("home.Signup") }}</span>
          </div>
          <div class="loginBox" :class="showNavbar2 ? 'login2' :'login'" v-else @click="gocloud()">
            <span>{{ $t("home.Enterthewebdisk") }}</span>
            <img :src="showNavbar2 ? $utils.getPng('web4/gocloud2') : $utils.getPng('web4/gocloud1')"/>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="header-writing">
          <span class="title">{{ $t("home.Tenyearsinarow") }}</span>
          <p class="desc">{{ $t("home.Launched") }}</p>
          <div class="goregister" @click="islogin ? gocloud():goLogin(1, 'register')">
            <span>{{!islogin ? $t("home.Signup") : $t("home.Enter")}}</span>
            <img :src="$utils.getPng('web4/goRegister')">
          </div>
        </div>

        <div class="all">
          <div class="box" >
           <div class="imgBox" id="lottieBox"></div>
            <!-- <img  class="imgBox" :src="$utils.getPng('web4/animation_img')" alt="" srcset=""
            :style="`will-change: transform; transform: translate3d(0px, 0px, 0px) scale3d(${x}, ${y}, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;`"/>
            <div v-show="showAnimation" id="lottie2" class="lottie right-top"></div>
            <div v-show="showAnimation" class="shadow-right"></div>
            <div v-show="showAnimation" id="lottie" class="lottie left-down"></div>
            <div v-show="showAnimation" class="shadow-left"></div> -->

            <!-- <div id="lottie3" class="lottie left-down"></div> -->
          </div>
        </div>

        <div class="wrap">
          <div class="main-wrap">
            <div class="static-video-box">
              <div class="static-area">
                <div class="area">
                  <div class="menu-box">
                    <div class="menu" @click="scrollTo(item.goId)" v-for="item in tabData" :key="item.goId" :class="{'menu2':curId==item.goId}">
                      <img :src="$utils.getPng('web4/icon'+item.id)">
                      <a class="aBtns">{{$t(item.name)}}</a>
                    </div>
                  </div>
                  <div class="video-box" v-show="curId=='a'">
                    <video class="video" muted loop :autoplay="autoplay" :src="this.$glb.cdnUrl+'/Public/web/video/web4/fastdownload2.mp4'"></video>
                  </div>
                  <div class="video-box" v-show="curId=='b'">
                    <video class="video" muted loop :autoplay="autoplay" :src="this.$glb.cdnUrl+'/Public/web/video/web4/onlineView.mp4'"></video>
                  </div>
                  <div class="video-box" v-show="curId=='c'">
                    <video class="video" muted loop :autoplay="autoplay" :src="this.$glb.cdnUrl+'/Public/web/video/web4/cloudUpzip.mp4'"></video>
                  </div>
                  <div class="video-box" v-show="curId=='d'">
                    <video class="video" muted loop :autoplay="autoplay" :src="this.$glb.cdnUrl+'/Public/web/video/web4/multiplatform.mp4'"></video>
                  </div>
                  <div class="video-box" v-show="curId=='e'">
                    <video class="video" muted loop :autoplay="autoplay" :src="this.$glb.cdnUrl+'/Public/web/video/web4/sharingprofits.mp4'"></video>
                  </div>
                </div>
              </div>
            </div>
            <div class="scroll-box" ref="scrollContainer">
              <div class="video-text-box" ref="a">
                <span class="title">{{ $t("home.FastDownload") }}</span>
                <p><font style="color:#FEA302">{{ $t("home.Extreme1") }}</font>
                  {{ $t("home.Extreme2") }}
                </p>
                <!-- <span class="desc">已全面接入Huawei Cloud&Tencent Cloud部署于全球的内容分发网络</span> -->
              </div>
              <div class="video-text-box" ref="b">
                <span class="title">{{ $t("home.OnlinePreview") }}</span>
                <p><font style="color:#2EA45D">{{ $t("home.Easy1") }}</font>
                  {{ $t("home.Easy2") }}
                </p>
                <!-- <span class="desc">图片、视频、音频、Word、Execl、PPT、TXT等各类格式均支持预览</span> -->
              </div>
              <div class="video-text-box" ref="c">
                <span class="title">{{ $t("home.Clouddecompression") }}</span>
                <p><font style="color:#9F7CEE">{{ $t("home.Oneclick1") }}</font>
                  {{ $t("home.Oneclick2") }}
                </p>
                <span class="desc">{{ $t("home.Support") }}</span>
              </div>
              <div class="video-text-box" ref="d">
                <span class="title">{{ $t("home.MultiPlatformSupport") }}</span>
                <p><font style="color:#3984F4">{{ $t("home.Crossplatform1") }}</font>
                  {{ $t("home.Crossplatform2") }}
                </p>
              </div>
              <!-- <div class="video-text-box" ref="e">
                  <span class="title">{{ $t("home.Shareandearn") }}</span>
                  <p><font style="color: #FF7F77">{{ $t("home.Rebate1") }}</font>
                    {{ $t("home.Rebate2") }}
                  </p>
                  <span class="link">{{ $t("home.participate1") }} <font @click="gollz">{{ $t("home.participate2") }}</font></span>
              </div> -->
            </div>
          </div>
        </div>

        <div class="subs">
          <!-- <div class="sub-box">
            <div class="left">
              <span>海量订阅号</span>
              <span>飞猫盘订阅号，享你所享，关注UP主后第一时间接收UP主分享的最新资讯与历史分享内容。</span>
            </div>
            <div class="right">
                <div class="container">
                  <div  id="lottie3" class="lottie3"></div>
                </div>
            </div>
          </div> -->
          <!-- <div class="sub-bg" :style="'background-image:url('+$utils.getPng('web4/sub_bg')+')'">
            <div class="go-sub">
              <span>{{islogin ? '前往订阅号  免费下载' : '即刻注册 免费下载' }}</span>
              <span class="btn" @click="islogin ? gohome() : goLogin(2, 'register')">{{islogin ? '立即前往' : '立即注册'}}</span>
            </div>
          </div> -->
        </div>

        <div class="comment-box">
          <span class="title">{{ $t("home.estimation") }}</span>
          <div class="comment-body">
            <div style="width: 100%; overflow: hidden;height:298px;min-width:1200px">
              <div class="demobox1"></div>
              <div class="demobox2"></div>
              <vue-seamless-scroll :data="commentData" :class-option="optionSetting" class="picMarquee-left">
                <div class="bd">
                  <ul class="infoList">
                    <li v-for="(item, index) in commentData" :key="index">
                      <p class="desc">{{ item.desc }}</p>
                      <div class="info">
                        <img :src="item.img"/>
                        <span> {{ item.name }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </vue-seamless-scroll>
              <vue-seamless-scroll style="transform: translateX(-170px)" :data="commentData2" :class-option="optionSetting" class="picMarquee-left">
                <div class="bd">
                  <ul class="infoList">
                    <li v-for="(item, index) in commentData2" :key="index">
                      <p class="desc">{{ item.desc }}</p>
                      <div class="info">
                        <img :src="item.img"/>
                        <span> {{ item.name }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>

        <!-- <div class="resources-bg" :style="'background-image:url('+$utils.getPng('web4/resources_bg')+')'">
          <div class="go-sub">
            <span>资源不用找，下载不等待</span>
            <span class="btn" @click="islogin ? gocloud() : goLogin(1, 'register')">{{islogin ? '进入我的飞猫盘' : '立即注册'}}</span>
          </div>
        </div> -->

        <div class="download-box">
          <div class="item" v-for="(item,index) in downloadData" :key="index">
            <div class="box">
                <img :src="$utils.getPng('web4/dl_icon'+item.id)"/>
                <span class="name">{{$t(item.name)}}</span>
                <span @click="onClike(item)" class="btn">{{ $t("home.DownloadNow") }}</span>
                <!-- <span @click="onClike(item)" class="btn" :class="{'btn2':item.id==4 && pcSwitch=='0'}">{{item.id==4 && pcSwitch=='0' ? '即将上线':'立即下载'}}</span> -->
            </div>
            <div class="box2">
              <div class="imgBox">
                <img class="ewm" :src="$utils.getPng('downapp')" />
              </div>
              <div class="tit1">{{ $t("home.ScanQRcodedownload") }}</div>
            </div>
          </div>
        </div>

        <div class="cright">
          <span>FEEMOO LIMITED</span>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
import data from "../../utils/animation/progressBar";
import data2 from "../../utils/animation/comment";
import data3 from "../../utils/animation/avatar";
import homeData from "../../utils/animation/homeFirst";
import lottie from 'lottie-web'
import {getHomeIndex ,clientDownUrlAPI,checkLoginFlag} from '@/utils/apiList/home'
export default {
  name: "firstPage",
  data() {
    return {
      x: 1,
      y: 1,
      appIndex: 0,
      navData: ["home.Home","home.Clouddrive","home.VIP"],
      islogin:false,
      showNavbar2:false,
      animation:'',//下载进度条动画
      animation2:'',//评论动画
      animation3: '',//订阅号头像动画
      animation4:'',//首页动画
      tabData:[
        {id:1,name:'home.FastDownload',goId:'a'},
        {id:22,name:'home.OnlinePreview',goId:'b'},
        {id:3,name:'home.Clouddecompression',goId:'c'},
        {id:4,name:'home.MultiPlatformSupport',goId:'d'},
        // {id:5,name:'home.Shareandearn',goId:'e'}
      ],
      downloadData:[
        {id:1,name:'home.AndroidClient'},
        {id:2,name:'home.iOSClient'},
        {id:3,name:'home.WindowsDesktop'},
        {id:4,name:'home.MacOSDesktop'},
      ],
      showAnimation:false,
      commentData:'',
      commentData2:'',
      curId:'a',
      autoplay:true,
      avaterList:[],
      pcSwitch:'',
      loading:true,
      frameUrl: "",
      tagArray: [],
      showBanner: 0, //显示不限时长广告
      iamvip: 0, //是否是会员 0不是会员。1是会员
      table: [
        {
          icon: this.$utils.getPng("icon12"),
          tit: "开通会员",
        },
        {
          icon: this.$utils.getPng("icon15"),
          tit: "我的订阅",
        },
        {
          icon: this.$utils.getPng("icon13"),
          tit: "帮助",
        },
        {
          icon: this.$utils.getPng("icon14"),
          tit: "设置",
        },
      ],
      showDropItem: false,
      fmpUrl:"https://www.feemoo.com" ,//这是飞猫盘海外版 用feemoo
    };
  },
  watch: {
    showAnimation(newval, oldval) {
      if (newval === true) {
         this.animation4.pause()
         this.animation4.play()
      } else {
         this.animation4.pause()
      }
    },
  },
  computed: {
    optionSetting() {
      return {
        step: 0.43, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100, // 单步运动停止的时间(默认值1000ms)
      };
    },
    language() {
      return this.$store.state.language;
    },
  },
  created() {
    this.islogin = this.$utils.getCookie(this.$glb.fmCookieName) !== null ? true : false
    this.Dow();
    this.checkLoginFun();
    this.getSubAvatar();
    this.animation4 = '',
    lottie.destroy()
    setTimeout(() => {
     this.getAnimation()
      this.animation4.pause()
    }, 200);
    // this.getuser()
  },
  components: {
  },
  mounted(){
    document.getElementById("homepage").scrollTo(0, 0);
    document.addEventListener("visibilitychange", this.handleVisiable);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  methods: {
    showDropFun: _.debounce(
      function () {
        this.showDropItem = !this.showDropItem;
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    changeLanguage(index) {
      this.$utils.changeLanguage(this.$route,index)
    },
     handleVisiable(e) {
      switch (e.target.visibilityState) {
        case "prerender":
          break;
        case "hidden":
          break;
        case "visible":
          this.checkLoginFun();
          break;
      }
    },
    getuser(){
        if (this.$utils.getCookie(this.$glb.fmCookieName) !== null) {
        this.$api.post("/center/getuser", {}, (res) => {
          if (!res.status) {
            return;
          }
          this.frameUrl = res.data.frameUrl;
          this.proYoungVipId = res.data.proYoungVipId;
          this.tagArray = res.data.tagArray;
          this.iamvip = res.data.iamvip;
          this.showBanner = res.data.showBanner;
        });
      }
    },
    enlargeRandomCircle() {
      const circles = document.querySelectorAll('.circle');
      const randomIndex = Math.floor(Math.random() * circles.length);
      const selectedCircle = circles[randomIndex];
      selectedCircle.classList.add('active');
      circles.forEach(circle => {
        if (circle !== selectedCircle) {
          circle.classList.remove('active');
        }
      });
    },
    scrollBox() {
      const container = this.$refs.scrollContainer
      const _scrollTop = this.$refs.addScroll.scrollTop
      const itemHeight = container.children[0].offsetHeight
      const index = Math.floor(_scrollTop / itemHeight)
      if(itemHeight < 800){
        this.curId = index ==2 ?'a' : index==3 ? 'b' : index==4 ? 'c' : index==5 ? 'd' : index==6 ? 'e' : 'a'
      }else{
          this.curId = index ==1 ?'a' : index==2 ? 'b' : index==3 ? 'c' : index==4 ? 'd' : index==5 ? 'e' : 'a'
      }
      this.showNavbar2=true
      if(this.$refs.addScroll.scrollTop==0){
        this.showNavbar2=false
        this.showAnimation=false
        this.animation='',
        this.animation2='',
        this.animation3 = ''
        // lottie.destroy()
      }
      var scrollTop = this.$refs.addScroll.scrollTop; // 滑入屏幕上方的高度
      var scrollHeight = this.$refs.addScroll.scrollHeight; // 监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let bili = (parseInt(scrollHeight) -(5 * parseInt(scrollTop))) / parseInt(scrollHeight);
      if(bili.toFixed(2)<0.95){
        this.showAnimation=true
      }
      if (bili < 0.8182) {
        return;
      }
      this.x = bili;
      this.y = bili;

    },
    getAnimation(){
      // this.animation = lottie.loadAnimation({
      //   container: document.getElementById('lottie'), // Required
      //   animationData: data,
      //   renderer: 'svg', // Required
      //   loop: false, // Optional
      //   autoplay: true, // Optional
      // })
      // this.animation2 = lottie.loadAnimation({
      //   container: document.getElementById('lottie2'), // Required
      //   animationData: data2,
      //   renderer: 'svg', // Required
      //   loop: false, // Optional
      //   autoplay: true, // Optional
      // })
      // this.animation3 = lottie.loadAnimation({
      //   container: document.getElementById('lottie3'), // Required
      //   animationData: data3,
      //   renderer: 'svg', // Required
      //   loop: true, // Optional
      //   autoplay: true, // Optional
      // })
      
      this.animation4 = lottie.loadAnimation({
        container: document.getElementById('lottieBox'), // Required
        animationData: homeData,
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
      })
    },
    scrollTo(id) {
      // 获取对应模块的位置
      const module = this.$refs[id];
      // 滚动到对应模块
      module.scrollIntoView({ behavior: 'smooth' });
    },
    getSubAvatar(){
      getHomeIndex()
        .then((res) => {
          if(res.status=='1'){
            var commentList=res.data.iconList//评论
            this.commentData= commentList.slice(0, 4)
            this.commentData2= commentList.slice(4, 8)
            this.pcSwitch=res.data.pcSwitch//mac桌面端 开关
            let arr = data3.assets
            for(var i=0;i<arr.length;i++){
              for(var j=0;j<res.data.logoInfo.length;j++){
                if(i==j){
                  arr[i].p = res.data.logoInfo[j].name
                  arr[i].u = res.data.logoInfo[j].path
                }
              }
            }
            data3.assets=arr
          }else{

          }
        })
        .catch((err) => {
        });
    },
    goLogin(type, state) {
      let query = {
        path: this.$store.state.fmPath
      }
      if (state === 'register') {
        query.tab = '2'
      }
      if (type != 1) {
        query.path = 'home'
      }
      // this.$router.push({ path: "/login", query });
      window.location.href = `${this.fmpUrl}/login?path=${query.path}${state == 'register' ?'&tab=2' : ''}`
    },
    gocloud(){
      if(this.islogin){
        // this.$router.push({ path: this.$store.state.fmPath });
        window.location.href = `${this.fmpUrl}/${this.$store.state.fmPath}`
      }else{
        let query = {
          path: this.$store.state.fmPath
        }
        // this.$router.push({ path: "/login", query });
        window.location.href = `${this.fmpUrl}/login?path=${query.path}`
      }
    },
    gohome(){
      window.open(this.$glb.Jmdyh + `/popularhome?platfro=${localStorage.getItem('platfro')}`, "_blank")
      // this.$router.push({ path: "/home" });
    },
    gollz(){
      window.open('https://daren.feimaoyun.com/feimaopan')
    },
    goRouter(index){
      if(index==0){
        // window.location.reload();
         window.location.href = this.fmpUrl
      }else if(index==1){
        if(this.islogin){
        this.$router.push({ path: this.$store.state.fmPath });
        // window.location.href = 'this.fmpUrl/drive'
      }else {
         window.location.href = `${this.fmpUrl}/login?path=drive`
      }
      }else if(index==2){
        if(this.islogin){
          this.$utils.routeModeReported('B8');
        }
        window.open(`${this.fmpUrl}/vip`,"_blank")
      }else{
        this.gollz()
      }
    },
    onClike(item) {
      if (item.id == 3) {
        // this.$utils.newReported('C/1-5-10-019');
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.win_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }else if(item.id == 4){
        // this.$utils.newReported('C/1-5-10-019');
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.mac_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }
    },
    Dow() {
      clientDownUrlAPI().then((res) => {
        if(res.status=='1'){
          this.exe = res.data;
        }
      })
    },
    checkLoginFun: _.debounce(
      function () {
       checkLoginFlag({}).then((res) => {
        if(res.status=='1'){
          if(res.data.status =='1'){
            this.islogin = true 
          }else {
            this.$utils.clearCookie(this.$glb.fmCookieName);
            this.islogin = false;
          }
        }
        })
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    loginout() {
      this.$api.post("/user/loginout", {}, (res) => {
        if (this.$route.path.substr(1, 2) == "ph") {
          this.$router.push({
            path: "/pubContent",
            query: {
              step: 0,
            },
          });
        }
        this.$utils.clearCookie(this.$glb.fmCookieName);
        this.$store.commit("setAttr", { name: "isLogin", val: false });
        this.$store.commit("setAttr", { name: "isElectron", val: false });
        window.location.reload();
        localStorage.setItem("vipid", 0);
        localStorage.setItem("uid", 0);
        localStorage.removeItem("ifrateflow");
        localStorage.removeItem("isSubscribe");
        localStorage.removeItem("isself");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("password");

        sessionStorage.removeItem("vip_mode");
        sessionStorage.removeItem("fromName");
        sessionStorage.removeItem("isFromRouter");
        this.$store.commit("setVuexState"); //重置缓存
      });
    },
    toOther(name) {
      this.userleave();
      if (name == "开通会员") {
        sessionStorage.setItem("vipRecord", 165);
      }
      name == "设置"
        ? this.$router.push({ name: "account" })
        : name == "开通会员"
        ? this.$router.push({ name: "vip" })
        : name == "我的订阅"
        ? this.$router.push({ name: "allsubscribes" })
        : this.$router.push({ name: "help" });
    },
    userleave() {
      this.flag = false;
    },
    goWelfare() {
      this.$utils.link("/welfare");
    },
  }
};
</script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>
