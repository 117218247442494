var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PayJurisdiction"},[_c('img',{staticClass:"PayJurisdictionbg",style:({
      width: !_vm.widthNum
        ? '1200px'
        : _vm.widthNum && _vm.windowHeight > 1000
        ? '762px'
        : 'auto',
      height: _vm.windowHeight < 1000 ? _vm.windowHeight - 252 + 'px' : '',
    }),attrs:{"src":_vm.$store.state.language == 'en'
        ? _vm.removePngExtension(_vm.$store.state.memberBenefitsImg[_vm.PayJurisUrl]) +
          '_' +
          _vm.$store.state.language +
          '_' +
          _vm.$store.state.theme +
          '.png'
        : _vm.removePngExtension(_vm.$store.state.memberBenefitsImg[_vm.PayJurisUrl]) +
          '_' +
          _vm.$store.state.theme +
          '.png',"alt":""}}),_c('span',{staticClass:"v-desc",style:({ marginTop: _vm.PayJurisUrl == 0 ? '12px' : '' })},[_vm._v(_vm._s(_vm.$t("vip.vipIntr")))])])
}
var staticRenderFns = []

export { render, staticRenderFns }