<template>
  <div class="electron-box">
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      top="0vh"
      :show-close="false"
      :visible.sync="isAppDownFile"
      width="480px"
    >
      <div class="d-header">
        <div class="leftOne">
          <div class="d-tit">扫码下载</div>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="AppDownFilecen">
        <div class="qrcodeVer-box">
          <div id="qrcodeVer"></div>
        </div>
        <div class="text1">已含取件码，扫码后无需再次输入</div>
        <div class="text2" @click="toPath">
          没有安装？去下载 <span class="span1">飞猫盘</span> APP
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isAppDownFile: {
      type: Boolean,
      default: false,
    },
    qrcode: {
      type: String,
      default: '',
    }
  },
  data() {
    return {};
  },
  watch: {
    isAppDownFile(newVal, oldVal) {
      if (newVal) {
        setTimeout(() => {
          this.createCode();
        }, 0);
      }
    },
  },
  mounted() {},
  methods: {
    toPath() {
      const routeUrl = this.$router.resolve({
        path: "/app",
      });
      window.open(routeUrl.href, "_blank");
    },
    //生成APP扫码二维码
    async createCode() {
      var code = JSON.stringify(this.qrcode);
      var qr = qrcode(0, "L");
      qr.addData(code);
      qr.make();
      document.getElementById("qrcodeVer").innerHTML = qr.createImgTag(5, 0);
    },
    handleClose() {
      this.$emit("Visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  height: auto;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.leftOne {
  display: flex;
  align-items: center;
  .d-tit {
    font-size: 16px;
    font-weight: 500;
    @include useTheme {
      color: getVar("text-color1-2c2c2c");
    }
  }

  .d-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.AppDownFilecen {
  text-align: center;
  .qrcodeVer-box {
    width: 164px;
    height: 164px;
    background: #ffffff;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    @include useTheme {
      border: getVar('border-color-e5e5e5');
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 12px auto;
  }
  .text1 {
    height: 22px;
    font-weight: 400;
    font-size: 14px;
    @include useTheme {
      color: getVar("text-color1-8a8b92");
    }
    line-height: 22px;
    margin-bottom: 28px;
  }
  .text2 {
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    @include useTheme {
      color: getVar("text-color1-2c2c2c");
    }
    line-height: 24px;
    cursor: pointer;
    margin-bottom: 8px;

    .span1 {
      color: #f67942;
    }
  }
}
</style>
